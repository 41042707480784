import React from 'react'
import { navigate } from 'gatsby-link'

import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <Navbar page='contact' />
          <div className="container">
          <div className="content contact-div">
            <h1 className="page-header">
              Say Hi!
            </h1>

            <div className="column is-6 has-text-centered">

              <div style={{"marginBottom": "25px"}}>
                Email <br />
                <a className="link" style={{"fontSize": "20px"}} href="mailto:tahmeedtarek@gmail.com">tahmeedtarek@gmail.com</a>
              </div>
              <div style={{"marginBottom": "25px"}}>
                Messenger <br/>
                <a className="link" style={{"fontSize": "20px"}} href="https://m.me/tahmeed156">tahmeed156</a>
              </div>
              <div style={{"marginBottom": "25px"}}>
                Phone <br/>
                <a className="link" style={{"fontSize": "20px"}} href="tel:+8801764496362">+8801764496362</a>
              </div>
            </div>

          </div>
        </div>

        <Footer />
      </Layout>
    )
  }
}
